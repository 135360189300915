import axios, { baseURL } from '@axios'
import { paginateArray, sortCompare } from '@/common'
import { getUserData } from "@/auth/utils";
import router from '@/router'
export default {
  namespaced: true,
  state: {
   
  },
  getters: {},
  mutations: {},
  actions: {
    fetchCustomerList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
         const {
              q = '',
              perPage = 10,
              page = 1,
              sortBy = 'id',
              sortDesc = false,
            } = queryParams
            const payload = {
              key: q,
              dateFrom: null,
              dateTo: null,
              status: null
            }
        axios
          .get(`${baseURL}/api/Order/GetVCustomerByKey?key=${q}`)
          .then(response => {
           
            resolve({
              data: paginateArray(response.data, perPage, page),
              total: response.data.length,
            })
          }
           )
          .catch(error => reject(error))
      })
    },

    fetchOrderList(ctx, queryParams) {
      // console.log('xxxxx',router.currentRoute.params.order);
      return new Promise((resolve, reject) => {
         const {
              q = '',
              perPage = 10,
              page = 1,
              sortBy = 'id',
              sortDesc = false,
            } = queryParams
            const payload = {
              order_mobile: router.currentRoute.params.order,
            }
        axios
          .post(`${baseURL}/api/Order/GetSalerOrderByVCustomerMobile`, payload)
          .then(response => {
            resolve({
              data: paginateArray(response.data.data, perPage, page),
              total: response.data.data.length,
            })
          }
           )
          .catch(error => reject(error))
      })
    },
  },
}
