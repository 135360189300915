import { ref, watch, computed } from "@vue/composition-api";
import store from "@/store";
// import { title } from '@core/utils/filter'

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import { getUserData } from "@/auth/utils";

export default function useCustomerList() {
  // Use toast
  const toast = useToast();

  const refCustomerListTable = ref(null);

  // Table Handlers
  const tableColumns = [ 
    { key: "index", label: "ลำดับ"},
    { key: "order_fullname", label: "ชื่อ - นามสกุล", sortable: true },
    { key: "order_mobile", label: "เบอร์โทรศัพท์", sortable: true },
    { key: "order_address_detail", label: "ที่อยู่", sortable: true },
    { key: "order_province", label: "จังหวัด", sortable: true },
    { key: "order_district", label: "เขต/ อำเภอ", sortable: true },
    { key: "order_sub_district", label: "แขวง/ ตำบล", sortable: true },
    { key: "order_zipcode", label: "รหัสไปรษณีย์", sortable: true },
  ];
  const perPage = ref(10);
  const totalCustomers = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref("");
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);
  const customerNameFilter = ref(null);
  const customerCategoryFilter = ref(null);
  const statusFilter = ref(null);

  const dataMeta = computed(() => {
    const localItemsCount = refCustomerListTable.value
      ? refCustomerListTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalCustomers.value,
    };
  });

  const refetchData = () => {
    refCustomerListTable.value.refresh();
  };

  watch(
    [
      currentPage,
      perPage,
      searchQuery,
      customerNameFilter,
      customerCategoryFilter,
      statusFilter,
    ],
    () => {
      refetchData();
    }
  );

  const fetchCustomers = (ctx, callback) => {
    const userData = getUserData();

    store
      .dispatch("customer-list/fetchCustomerList", {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        // customerName: customerNameFilter.value,
        // customerCategory: customerCategoryFilter.value,
        // status: statusFilter.value,
      })
      .then((response) => {
        const { data, total } = response;
       

        callback(data);
        totalCustomers.value = total;
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching customers list",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveStatusVariant = (status) => {
    if (status === "Popular Customers") return "primary";
    if (status === "Best Seller" || status === "Active") return "success";
    if (status === "Recommended Customers") return "info";
    if (status === "Inactive") return "secondary";
    return "primary";
  };

  return {
    fetchCustomers,
    tableColumns,
    perPage,
    currentPage,
    totalCustomers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refCustomerListTable,

    resolveStatusVariant,
    refetchData,

    // Extra Filters
    customerNameFilter,
    customerCategoryFilter,
    statusFilter,
  };
}
