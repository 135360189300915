import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useOrderList() {
  // Use toast
  const toast = useToast()

  const refOrderListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: "index", label: "ลำดับ" },
    { key: "orderNo", label: "หมายเลขคำสั่งซื้อ" },
    {
      key: "createdDateText",
      label: "วันที่สั่งซื้อ",
    },
    { key: "contactName", label: "ชื่อ - นามสกุล" },
    { key: "contactMobile", label: "เบอร์โทรศัพท์" },
    {
      key: "totalPriceStd",
      label: "ยอดคำสั่งซื้อ (บาท)",
      formatter: (val) => `${val.toLocaleString("en-US")}`,
    }, // ไม่มี
    { key: "userFullName", label: "ชื่อผู้ใช้งาน" }, // ไม่มี
    { key: "orderLists", label: "รายละเอียดสินค้า" }, // ไม่มี
    {
      key: "orderStatus",
      label: "สถานะคำสั่งซื้อ",
      formatter: (val) => changeText(val),
    },
  ]

  const perPage = ref(10)
  const totalUsers = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const roleFilter = ref(null)
  const planFilter = ref(null)
  const statusFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refOrderListTable.value ? refOrderListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalUsers.value,
    }
  })

  const refetchData = () => {
    refOrderListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, roleFilter, planFilter, statusFilter], () => {
    refetchData()
  })

  const fetchOrderList = (ctx, callback) => {
    store
      .dispatch('order-list/fetchOrderList', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        // role: roleFilter.value,
        // plan: planFilter.value,
        // status: statusFilter.value,
      })
      .then(response => {
        console.log('response', response);
        const { data } = response;
       
        console.log('datadatadatadata', data);
        callback(data);
        totalUsers.value = response.total;
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching order list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*
  const changeText = (val) => {
    let text = "";
    switch (val) {
      case 0:
        text = "ยกเลิก ";
        break;
      case 1:
        text = "รับคำสั่งซื้อ";
        break;
      case 2:
        text = "รอชำระ";
        break;
      case 3:
        text = "ยืนยันคำสั่งซื้อ";
        break;
      case 4:
        text = "กำลังจัดส่ง";
        break;
      case 5:
        text = "จัดส่งแล้ว";
        break;
      default:
        text = "รับคำสั่งซื้อ";
    }
    return text;
  };

  const changeTextP = (val) => {
    let text = "";
    switch (val) {
      case 0:
        text = "";
        break;
      case 1:
        text = "โอนเงิน";
        break;
      case 2:
        text = "COD";
        break;
      case 3:
        text = "CreditCard";
        break;
    
      default:
        text = "รอการชำระเงิน ";
    }
    return text;
  };

  const resolveUserRoleVariant = role => {
    if (role === 'subscriber') return 'primary'
    if (role === 'author') return 'warning'
    if (role === 'maintainer') return 'success'
    if (role === 'editor') return 'info'
    if (role === 'admin') return 'danger'
    return 'primary'
  }

  const resolveUserRoleIcon = role => {
    if (role === 'subscriber') return 'UserIcon'
    if (role === 'author') return 'SettingsIcon'
    if (role === 'maintainer') return 'DatabaseIcon'
    if (role === 'editor') return 'Edit2Icon'
    if (role === 'admin') return 'ServerIcon'
    return 'UserIcon'
  }

  const resolveUserStatusVariant = status => {
    if (status === true) return 'success'
    if (status === 'pending') return 'warning'
    if (status === 'active') return 'success'
    if (status === 'inactive') return 'secondary'
    return 'primary'
  }

  return {
    fetchOrderList,
    tableColumns,
    perPage,
    currentPage,
    totalUsers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refOrderListTable,

    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveUserStatusVariant,
    refetchData,

    // Extra Filters
    roleFilter,
    planFilter,
    statusFilter,
  }
}
